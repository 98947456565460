<template>
  <v-layout class="welcome-page-content" justify-center align-center>
    <v-progress-linear color="secondary" indeterminate></v-progress-linear>
  </v-layout>
</template>

<script>
import { OnlineApi } from '@/api'
import { Tool, Cache } from '@/assets/js/util'

export default {
  components: {
  },
  data: () => ({
    assists: {
      tool: new Tool(),
      cache: new Cache()
    }
  }),
  mounted () {
    this.instantCredential()
  },
  methods: {
    instantCredential () {
      const resource = this.$route.params?.resource
      // 暂存路径，方便域名切换时跳转
      this.$store.commit('setRedirectUrlParameter', this.$route.path)
      if (this.assists.tool.isNotEmpty(resource)) {
        OnlineApi.parseSignatureResource(
          resource,
          (res) => {
            this.$store.commit('setPortfolioId', res.portfolioId)
            this.store.commit('setLoan', { ...this.$store.getters.getLoan, loanNo: res.loanNo })
            this.$store.commit('setResource', resource)
            if (res.isConsent && this.assists.tool.isNotEmpty(res.signatureUrl)) {
              this.$router.push('/loan-agreement')
              this.$store.commit('setDocumentUrl', `${res.signatureUrl}?embed=1`)
            } else {
              this.$store.commit('setIsConsent', res.isConsent)
              this.$router.push('/consent')
            }
          },
          (err) => this.assists.tool.setPopupInformation(err.message)
        )
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.welcome-page-content
  height: 100%
  width: 100%
  background: var(--theme_primary)
  padding: 0 30%
</style>
